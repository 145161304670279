@import 'src/design-system/styles/layout-variables';
@import 'src/design-system/styles/typography.mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: $content-indent;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  @include font-style-h4($font-weight: $font-weight-regular);
  margin: 0;
}

.infoButtons {
  display: flex;
  gap: 16px;
}

.progressBar {
  width: 415px;
}

